<template>
  <div class="productEditContainer pb40 ignore_view">
    <div class="mt40 mb25">
      <div
        class="d-flex bgwhite backBtn j-center userSelectNone a-center commontransition cursorPointer primaryHover"
        @click="goToMyProduct"
      >
        Back
      </div>
    </div>
    <a-form
      :form="form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
    >
      <div class="d-flex">
        <div>
          <div class="leftContainer mr40 bgwhite p24" @click="editSelectProduct(index)">
            <div class="fontBold">
              {{ uploadProductItem.blankProductModel }}
            </div>
            <img
              class="productImg mt24"
              :src="uploadProductItem.blankProductMainImageUrl"
            >
          </div>
        </div>
        <div class="rightContainer bgwhite p24 mb15">
          <div class="font20 fontBold">Submit Designs Images</div>

          <div>
            <div class="fontBold mb30 mt30">{{ uploadProductItem.blankProductModel }}</div>

            <div>
              <div name="label">Print File <span class="colorFD051D">*</span></div>
              <multi-image-upload placeholder="Click to upload a file" :image-urls.sync="uploadProductItem.printImageList" :max-image-num="5" />
            </div>

            <div name="label">
              Display Mockup
              <span class="colorFD051D">*</span>
            </div>
            <multi-image-upload placeholder="Click to upload files. (Up to 5 files)" :image-urls.sync="uploadProductItem.effectImageList" :max-image-num="5" />

            <div name="label">Note</div>
            <a-input
              v-model="uploadProductItem.note"
              placeholder="Enter a note"
            />
            <div class="colorgraylightColor mt10">
              If you have specific requirement on template,please leave us a note here.
            </div>
          </div>
        </div>
      </div>
      <div class="rightContainer bgwhite p24 saveView ml-auto">

        <div>
          <div name="label" class="pt28 pb14">Email</div>
          <a-form-item>
            <a-input
              v-model="uploadProductItem.noteEmail"
              type="email"
              :max-length="100"
              placeholder="Enter your email here."
            />
          </a-form-item>
          <div class="colorgraylightColor mt10">
            Please leave us your email here for furture contact.
          </div>
        </div>
        <a-form-item>
          <div class="d-flex">
            <a-button
              type="primary"
              :loading="batchUploadProductLoading"
              class="saveBtn ml-auto"
              @click="handleSubmit"
            >Save</a-button>
          </div>
        </a-form-item>
      </div>
    </a-form>
    <select-product-modal
      ref="selectProductModal"
      @select-product="doSelectProduct"
    />
  </div>
</template>

<script>
import SelectProductModal from './components/select_product_modal.vue'
import MultiImageUpload from '@/components/multi_image_upload'
import { editUploadCustomProduct, uploadCustomProductDetail } from '@/api/product'
export default {
  name: 'UploadProductEdit',
  components: {
    SelectProductModal,
    MultiImageUpload
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      form: undefined,
      noteEmail: '',
      uploadProductItem: {},
      toSelectProductIndex: null,
      batchUploadProductLoading: false
    }
  },
  mounted() {
    this.getDetail()
    this.form = this.$form.createForm(this, { name: 'coordinated' })
  },
  methods: {
    getDetail() {
      uploadCustomProductDetail({ id: this.id }).then(response => {
        if (response.code === 200) {
          this.uploadProductItem = response.data || {}
        }
      })
    },

    async handleSubmit() {
      this.batchUploadProductLoading = true
      try {
        if (this.uploadProductItem.printImageList.length === 0) {
          this.$message.error(`Please upload image`)
          return
        }
        if (this.uploadProductItem.effectImageList.length === 0) {
          this.$message.error(`Please upload mockup image`)
          return
        }
        var postData = Object.assign({}, this.uploadProductItem)
        postData.id = this.id
        const response = await editUploadCustomProduct(postData)
        if (response.code === 200) {
          this.$message.success('Success')
          this.goToMyProduct()
        } else {
          this.$message.error(response.msg)
        }
      } finally {
        this.batchUploadProductLoading = false
      }
    },
    goToMyProduct() {
      this.callNewPage(`/my-product`)
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../assets/less/theme.less";
.productEditContainer {
  width: @pageWidth;
  margin: 0 auto;
  .pt28 {
    padding-top: 28px;
  }
  .pb14 {
    padding-bottom: 14px;
  }
  .backBtn {
    width: 77px;
    height: 40px;
    border-radius: 8px;
  }
  .leftContainer {
    width: 331px;
    border-radius: 12px;
    top: 80px;
    .productImg {
      width: 261px;
      height: 261px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .rightContainer {
    width: 981px;
    border-radius: 12px;
    .colorFD051D {
      color: #fd051d;
    }
    .addProductBtn {
      width: 500px;
      height: 44px;
      background: #eff8ff;
      border-radius: 8px;
    }
    .imguploadItem {
      .uploadedimg {
        width: 72px;
        height: 72px;
        border-radius: 8px;
      }
      .colorIcon {
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
      }
    }
    .saveBtn {
      width: 140px;
      height: 44px;
    }
    .uploadBtn {
      width: 981px;
      background: #f2f6ff;
      border: 1px dashed #4cbbf9;
      border-radius: 10px;
      .uploadicon {
        width: 50px;
        height: 50px;
      }
    }
  }
  .saveView {
    width: calc(100% - 371px);
  }
}
</style>
