<template>
  <a-modal
    :title="false"
    :visible.sync="visible"
    :footer="null"
    centered
    :width="computedWidth"
    @cancel="visible = false"
  >
    <div class="selectProductContainer">
      <div class="content-view">
        <div class="contentTitle font-bold font16 ff-ss pb10">
          Select Product
        </div>
        <div class="pb15">
          <a-input-search v-model="keyword" placeholder="Search Product" class="inputSearch" @change="reloadProducts" />
        </div>
        <div class="d-flex">
          <div class="leftMenuView">
            <!-- <div class="content-view-left positionSticky"> -->
            <div class="content-view-left">
              <div class="title mb10">Categories</div>

              <a-menu
                class="leftMenuViewInner"
                :default-selected-keys="[-1]"
                mode="inline"
                @click="handleProductTypeChange"
              >
                <a-menu-item :key="-1" :title="'All categories'">
                  All categories
                </a-menu-item>
                <template v-for="topProductType in productTypes">
                  <a-sub-menu
                    v-if="topProductType.children"
                    :key="topProductType.id"
                  >
                    <span slot="title" :title="topProductType.name">
                      <span>{{ topProductType.name }}</span>
                    </span>
                    <a-menu-item
                      v-for="subProductType in topProductType.children"
                      :key="subProductType.id"
                    >
                      {{ subProductType.name }}
                    </a-menu-item>
                  </a-sub-menu>
                  <a-menu-item
                    v-else
                    :key="topProductType.id"
                    :title="topProductType.name"
                  >
                    {{ topProductType.name }}
                  </a-menu-item>
                </template>
              </a-menu>
            </div>
          </div>
          <div class="rightProductView d-flex flex-column">
            <div class="rightProductContent">
              <div v-for="(item) in products" :key="item.id" class="rightProductItem text-center" @click="onSelectProduct(item,$event)">
                <img class="productImg" :src="item.mainShowImageUrl">
                <div class="name font16 ff-ss fc-333">{{ item.model }}</div>
                <div class="price font20 ff-ss font-bold">
                  <span v-if="item.favorablePrice">
                    <span class="fs-16">${{ item.favorablePrice | MoneyFormat }}</span>
                    <div class="fs-16" :style="{color: '#949494'}"><s>${{ item.price | MoneyFormat }}</s></div>
                  </span>
                  <span v-else>
                    <span class="fs-16">${{ item.price | MoneyFormat }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="paginationView d-flex a-center j-center mt-auto">
              <a-pagination
                :page-size.sync="pageSize"
                class="ml-auto"
                size="small"
                :total="productsNum"
                @change="pageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import defaultImg from '@/assets/images/Home/banner.png'
import { blankProductPage, typeTree } from '@/api/product'
import blankProductTypeEnum from '@/enums/blank_product/blankProductTypeEnum'
export default {
  data() {
    return {
      defaultImg,
      confirmLoading: false,
      openKeys: ['sub1'],
      selectedKeys: ['1'],
      currentPage: 1,
      pageSize: 8,
      selectProductTypeId: null,
      keyword: null,
      productTypes: [],
      products: [],
      productsNum: 0,
      visible: false
    }
  },
  computed: {
    computedWidth() {
      return `${780 / 16}vw`
    }
  },
  mounted() {
    this.loadProductTypes()
  },
  methods: {
    async loadProductTypes() {
      const { data } = await typeTree({})
      this.productTypes = data
    },
    async loadProducts() {
      var postData = {}
      postData.typeId = this.selectProductTypeId
      postData.current = this.currentPage
      postData.size = this.pageSize
      postData.keyword = this.keyword
      postData.types = [blankProductTypeEnum.DIY.value, blankProductTypeEnum.JET.value]
      var { data } = await blankProductPage(postData)
      this.productsNum = data.total
      this.products = data.records
    },
    show() {
      this.loadProducts()
      this.visible = true
    },
    reloadProducts() {
      this.currentPage = 1
      this.loadProducts()
    },
    handleProductTypeChange(e) {
      if (e.key === -1) {
        this.selectProductTypeId = null
      } else {
        this.selectProductTypeId = e.key
      }
      this.reloadProducts()
    },
    pageChange(page, pageSize) {
      this.currentPage = page
      this.pageSize = pageSize
      this.loadProducts()
    },
    onSelectProduct(item) {
      console.log('select product', item)
      this.$emit('select-product', item)
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../../../assets/less/theme.less";
.selectProductContainer {
  .inputSearch{
    height: 44px;
  }
  .content-view {
    .contentTitle {
      width: 100%;
      text-align: left;
    }
    .leftMenuView {
      max-height: 50vh;
      width: 180px;
      overflow: hidden;
      overflow-y: auto;
      .commonBar(#D2D2D8,0px,0px);
      .content-view-left {
        // width: 168px;
        background-color: #ffffff;
        // top: 80px;
        border-radius: 10px;
        // padding: 12px;
        .title {
          // color: rgba(171, 174, 191, 100);
          padding-left: 10px;
          font-size: 16px;
        }
        .leftMenuViewInner{
          // width: 256px;
        }
        .ant-menu {
          width: 100% !important;
          border-right: none !important;
          font-size: 14px !important;
          /deep/ .ant-menu-submenu-title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px !important;
            padding-right: 30px !important;
            font-size: 14px !important;
          }
        }
        .ant-menu-item {
          min-width: 100% !important;
          padding-left: 20px !important;
          padding-right: 20px !important;
          font-size: 14px !important;
        }
      }
    }
    .rightProductView {
      flex: 1;
      .rightProductContent {
        .rightProductItem {
          cursor: pointer;
          margin-left: 25px;
          // margin-right: 10px;
          height: fit-content;
          display: inline-block;
          padding-bottom: 15px;
          .productImg {
            width: 110px;
            height: 110px;
            border-radius: 8px;
          }
          .name {
            padding-top: 10px;
            padding-bottom: 6px;
            width: 110px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .price {
            color: #009ef7;
          }
        }
      }
      .paginationView {
      }
    }
  }
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.ff-ss {
  font-family: Segoe UI-Regular, Segoe UI;
}
.fw-400 {
  font-weight: 400;
}
.fc-333 {
  color: #333333;
}
.fc-999 {
  color: #999999;
}
.h-auto {
  height: auto !important;
}

.ant-menu-item::after{
  border: none;
}
.ant-menu-item{
  border-radius: 12px !important;
}
.ant-menu-item-selected{
  color: rgba(0, 158, 247, 100) !important;
  background-color: rgba(242, 246, 255, 100) !important;
  border-radius: 12px !important;
}
</style>
