import OSS from 'ali-oss'
import { nanoid } from 'nanoid'

var cacheClient = null

// 获取缓存的客户端
function getCacheClient() {
  if (!cacheClient) {
    return null
  }
  // 缓存的客户端如果即将过期，则不使用
  if (cacheClient.expiration - 180 * 1000 < new Date().getTime()) {
    cacheClient = null
    return null
  }
  return cacheClient
}

async function oss(stsApi) {
  var client = getCacheClient()
  if (client) {
    return client
  }

  const response = await stsApi()
  const ossData = response.data
  var expiration = Date.parse(ossData.expiration)
  client = new OSS({
    endpoint: ossData.ossEndpoint,
    accessKeyId: ossData.accessKeyId,
    accessKeySecret: ossData.accessKeySecret,
    bucket: ossData.bucket,
    stsToken: ossData.securityToken
  })
  cacheClient = {
    client,
    expiration,
    domain: ossData.domain,
    ossObjectNamePrefix: ossData.ossObjectNamePrefix,
    bucket: ossData.bucket
  }
  return cacheClient
}

// 获取随机的objectName
function getRandomObjectName(ossObjectNamePrefix, ossDomain, filename) {
  const point = filename.lastIndexOf('.')
  const suffix = filename.substr(point)
  const fileName = filename.substr(0, point)
  const objectName = `${ossObjectNamePrefix}${nanoid()}${suffix}`
  const objectUrl = ossDomain + '/' + objectName
  return {
    objectName,
    fileName,
    objectUrl
  }
}

export default {
  oss,
  getRandomObjectName
}
